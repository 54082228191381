<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>

<script lang="ts" setup>
import zhCn from "element-plus/dist/locale/zh-cn.mjs"
import en from "element-plus/dist/locale/en.mjs"
import { onMounted, computed, ref } from 'vue';
import { debounce } from "lodash";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const $store = useStore()
const $route = useRoute()
const language = ref('zh-cn')
const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))

onMounted(() => {
  if ($route.path == '/login') return 
  $store.commit('nav/INIT_NAVLIST')
  $store.commit('user/SAVE_USERINFO')
  handleResizeObserver()
})

const handleResizeObserver = () => {
  const resizeObserver  = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends resizeObserver  {
    constructor(callback: (...args: any[]) => void) {
      callback = debounce(callback, 100);
      super(callback);
    }
  };
}
</script>
<style lang="less">
*{
  padding: 0;
  margin: 0;
}

@import '@/assets/css/iconfont.css';
</style>
