<template>
  <div id="pagination">
    <el-pagination 
      layout="prev, pager, next" 
      :total="$props.total" 
      :page-size="$props.pageSize"
      :background="$props.isBackground"
      @current-change="handleChangeCurrent"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

const $props = defineProps({
  isBackground: {
    type: Boolean,
    default: false
  },
  total: {
    type: Number,
    default: 0
  },
  pageSize: {
    type: Number,
    default: 0
  },
})
const $emits = defineEmits(['change:current'])

const handleChangeCurrent = (val: number) => {
  $emits('change:current', val)
}
</script>

<style lang="scss" scoped>

</style>