import { getQueryTask } from "@/request/api";
interface StateType {
    tasklist: Array<object>
    seite: Number,
    total: Number,
}
const state = {
  tasklist: [],
  seite:1,
  total:0
}

const getters = {
    getTaskList(state:StateType){
        return state.tasklist
    },
    getSeite(state:StateType){
        return state.seite
    },
    getTotal(state:StateType){
      return state.total
    }
}

const mutations = {
    REQ_TASKLIST(state:StateType,payload:Array<object>){
        state.tasklist = payload
    },
    REQ_SEITE(state:StateType,payload:number){
        state.seite = payload
    },
    REQ_TOTAL(state:StateType,payload:number){
        state.total = payload
    }
}

const actions = {
    getTaskListActions(context:any,payload:any){
        getQueryTask({seite:context.state.seite,...payload}).then((res:any)=>{
            context.commit('REQ_TASKLIST',res.data.queryTaskList)
            context.commit('REQ_TOTAL',res.data.total)
        })
    },
    changeSeite(context:any,payload:number){
        context.commit('REQ_SEITE',payload)
        context.dispatch('getTaskListActions')
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}