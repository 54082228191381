import axios from "axios";
import qsString from 'querystring'
import router from '../router'

// let baseUrl = '/PromotionAppuni'
let baseUrl = '/api'

axios.interceptors.request.use(req=>{
    // if(req.url != '/api/login'){
    //     if(sessionStorage.getItem("userinfo")){
    //         req.headers.Authorization = JSON.parse(sessionStorage.getItem("userinfo")|| "").token
    //     }
    // }
    return req
})

axios.interceptors.response.use(res=>{
    // if(res.data.msg=="请设置请求头,并携带验证字符串"){
    //     alert(res.data.msg)
    //     router.push('/login')
    // }
    // if(res.data.msg=='登录已过期或访问权限受限'){
    //     alert(res.data.msg)
    //     router.push('/login')
    // }
    return res.data
})

export const get = (url:string,params={})=>{
    return new Promise((resolve,reject)=>{
        axios.get(baseUrl + url,{
            params
        })
        .then(res=>resolve(res))
        .catch(err=>reject(err))
    })
}

export const post = (url:string,params:{},isFile=false)=>{
    let data:any = {}
    if(isFile){
        data = new FormData()
        for(let [key,value] of Object.entries(params)){
            data.append(key,value)
        }
    }else{
        data = params
    }
    return new Promise((resolve,reject)=>{
        axios.post(baseUrl + url, data)
        .then(res=>resolve(res))
        .catch(err=>reject(err))
    })
}