<template>
  <div id="voucher">
    <div class="header">
      <div class="slot-top search-list">
        <div class="box" v-root>
          <span>凭证：</span>
          <el-radio-group 
            v-model="formData.userId" 
            class="ml-4"
            @change="handleRadioChange"
          >
            <el-radio label="当前用户" :value="user_id" size="large"></el-radio>
            <el-radio label="所有用户" value="" size="large"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="search-list">
        <div class="box">
          <span class="name">描述内容：</span>
          <el-input
            placeholder="请输入描述内容"
            v-model="formData.voucherTest"
          ></el-input>
        </div>
        <div class="box">
          <span class="name">金额：</span>
          <el-input
            placeholder="请输入金额"
            v-model="formData.settlement"
          ></el-input>
        </div>
        <div class="box">
          <span class="name">审批状态：</span>
          <el-select 
            clearable
            class="m-2" 
            placeholder="请选择审批状态" 
            size="large"
            v-model="formData.state" 
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="box">
          <span class="name">任务名称：</span>
          <el-input
            placeholder="请输入任务名称"
            v-model="formData.taskName"
          ></el-input>
        </div>
        <div class="box">
          <span class="name">任务分类：</span>
          <el-select 
            clearable
            class="m-2" 
            placeholder="请选择任务分类" 
            size="large"
            v-model="formData.taskClass" 
          >
            <el-option
              v-for="item in options_taskClass"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="box">
          <span class="name">分类-类别：</span>
          <el-select 
            clearable
            class="m-2" 
            placeholder="请选择分类-类别" 
            size="large"
            v-model="formData.taskClassCategory" 
          >
            <el-option
              v-for="item in options_taskClassCategory"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="but-list">
        <el-button 
          type="primary"
          @click="handleSearch"
        >搜索</el-button>
        <el-button 
          type="primary"
          @click="handleReset"
        >重置</el-button>
        <el-button 
          type="danger"
          @click="handleDeletes"
        >删除</el-button>
      </div>
    </div>
    <div class="cen">
      <div class="table">
        <el-table 
          border
          :data="tableData" 
          @selection-change="handleSelectionChange"
        > 
          <el-table-column type="expand">
            <template #default="{ row }">
              <div class="imgs">
                <el-image
                  v-for="(item, index) in row.pictureUrl"
                  :key="index"
                  :initial-index="4"
                  :src="item"
                  :preview-src-list="[item]"
                  :preview-teleported="true"
                  style="width: 100px; height: 100px"           
                  fit="cover"
                ></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column type="index" label="序号" width="100" />
          <el-table-column prop="taskName" label="任务名称" width="180" />
          <el-table-column label="任务分类" width="100">
            <template v-slot="{ row }">
              {{ getTaskClass(row.taskClass) }}
            </template>
          </el-table-column>
          <el-table-column label="分类-类别" width="100">
            <template v-slot="{ row }">
              {{ getTaskClassCategory(row.taskClassCategory) }}
            </template>
          </el-table-column>
          <el-table-column prop="voucherTest" label="描述" />
          <el-table-column label="审批状态" width="100">
            <template v-slot="{ row }">
              {{ getState(row.state) }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="发布用户" width="100"/>
          <el-table-column prop="reviewUserName" label="审查用户" width="100"/>
          <el-table-column prop="index" label="操作" width="100">
            <template v-slot="{ row }">
              <el-button
                v-if="row.state == 1"
                class="primary"
                @click="handleApproval(row)"
              >送审</el-button>
              <el-button
                class="delete"
                @click="handleDelte(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer">
      <GxPagination 
        :isBackground="true"
        :total="total"
        :pageSize="10"
        @change:current="handleChangeCurrent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { 
  inter_getVoucherList
} from '@/request/interface'
import { 
  getVoucherList, 
  voucherApproval,
  voucherDelte 
} from "@/request/api";
import GxPagination from '@/components/GxPagination.vue';

import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import { onMounted, ref, reactive, computed } from 'vue';
import { debounce } from 'lodash'
import { useStore } from 'vuex';

const $store = useStore()
const options = reactive([
  { value: 0, label: '审核通过' },
  { value: 1, label: '未审核' },
  { value: 2, label: '送审中' },
  { value: 3, label: '审核不通过' },
])
const options_taskClass = reactive([
  { value: 0, label: '常规任务' },
  { value: 1, label: '游戏任务' },
  { value: 2, label: '证券任务' },
  { value: 3, label: '金融任务' },
])
const options_taskClassCategory = reactive([
  { value: 0, label: '简单注册' },
  { value: 1, label: '每日推荐' },
  { value: 2, label: '实名认证' },
  { value: 3, label: '福利任务' },
])
let formData = ref<inter_getVoucherList>({
  userId: '',
  voucherTest: '',
  voucherUrl: '',
  settlement: '',
  state: '',
  seite: 1,
  taskName: '',
  taskClass: '',
  taskClassCategory: ''
})
let tableData = ref<any>([])
let total = ref<number>(0)
let user_id = ref<string>('')
let array_delIds = ref<any>("")

const getState = computed(() => {
  return function(state: any) {
    switch(parseInt(state)) {
      case 0:
        return '审核通过';
      case 1:
        return '未审核';
      case 2:
        return '送审中';
      case 3:
        return '审核不通过';
    }
  }
})
const getTaskClass = computed(() => {
  return function(state: any) {
    switch(parseInt(state)) {
      case 0:
        return '常规任务';
      case 1:
        return '游戏任务';
      case 2:
        return '证券任务';
      case 3:
        return '金融任务';
    }
  }
})
const getTaskClassCategory = computed(() => {
  return function(state: any) {
    switch(parseInt(state)) {
      case 0:
        return '简单注册';
      case 1:
        return '每日推荐';
      case 2:
        return '实名认证';
      case 3:
        return '福利任务';
    }
  }
})

onMounted(async () => {
  const userInfo = await $store.dispatch('getUserInfo')
  user_id.value = userInfo.id
  handleInit()
})

const handleInit = async () => {
  const loading = ElLoading.service({
    lock: true,
    text: '加载中',
    background: 'rgba(0, 0, 0, 0)',
  })
  const result = await getVoucherList(formData.value)
  loading.close()
  tableData.value = result.data.queryVoucherList
  total.value = result.data.total
}
const handleInitData = () => {
  for (let key in formData.value) {
    (formData.value[key as keyof inter_getVoucherList] as string) = ''
  }
  formData.value.seite = 1
}
const handleChangeCurrent = (val: number) => {
  formData.value.seite = val
  handleInit()
}
const handleSearch = debounce(() => {
  handleInit()
}, 100)
const handleReset = debounce(() => {
  handleInitData()
}, 100)
const handleRadioChange = () => {
  handleInit()
}
const handleApproval = (row: any) => {
  const _this = row
  ElMessageBox.confirm(
    '确定要将该凭证送审吗?',
    'Warning',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async () => {
      const res = await voucherApproval({
        id: row.id,
        reviewUser: row.userId,
        reviewUsername: row.userName,
        state: row.state
      })
      ElMessage.success('送审成功')
      handleInit()
    })
    .catch(() => { 
      ElMessage.error('送审失败')
      return 
    })
}
const handleDelte = (row: any) => {
  ElMessageBox.confirm(
    '确定要删除该凭证吗?',
    'Warning',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async () => {
      await voucherDelte(row.id)
      ElMessage.success('删除成功')
      handleInit()
    })
    .catch(() => { return })
}
const handleSelectionChange = (arr: any) => {
  array_delIds.value = arr.map((item: any) => item.id).join(',')
}
const handleDeletes = () => {
  if(!array_delIds.value) return 
  if (array_delIds.value) {
    ElMessageBox.confirm(
      '确定要删除选中的凭证吗?',
      'Warning',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
      .then(async () => {
        await voucherDelte(array_delIds.value)
        ElMessage.success('删除成功')
        handleInit()
        array_delIds.value = ''
      })
      .catch(() => { return })
  }
}
</script>

<style lang="less" scoped>
#voucher {
  > .header {
    > .search-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
      > .box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px;
        > span {
          white-space: nowrap;
        }
        > ::v-deep .el-select {
          width: 160px;
          height: 35px;
          > .el-select__wrapper  {
            height: 100%;
            min-height: 0px;
          }
        }
        > ::v-deep .el-radio-group {
          margin-left: 10px;
        }
      }
    }
    > .but-list {
      margin-top: 20px;
    }
  }
  > .cen {
    margin: 20px 0px;
    > .table {
      > ::v-deep .el-table--fit {
        .el-table__cell {
          > .imgs {
            padding: 10px;
            box-sizing: border-box;
            > .el-image {
              margin-right: 20px;
            }
          }
          &:last-of-type {
            > .cell {
              text-overflow: clip;
            }
          }
        }
        .cell {
          text-overflow: none;
          > img {
            width: 100px;
            height: 100px;
          }
          > .el-button {
            padding: 0px;
            height: 0px;
            border: none;
            &.primary {
              color: #409EFF
            }
            &.delete {
              color: red;
            }
          }
        }
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: center;
  }
}
</style>