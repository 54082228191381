import { get, post } from './index'
import type { dialog_formType } from '@/views/user/interface'
import type { TaskForm } from '@/views/task/interface'
import type {
  inter_taskDataMaintenance,
  inter_uploadImage,
  inter_getVoucherList,
  inter_voucherApproval,
  inter_cretifiList,
  inter_voucherAudit,
  inter_taskAddUploadImg,
  inter_taskInfoAddOrEdit
} from './interface'
import request from './request1'

// 登录管理员
// export const loginUser = (data: object) => post('/log', data)
export const loginUser = (params: object) => {
  return request({
    url: `/PromotionAppuni/log`,
    method: 'post',
    data: params
  })
}

// 修改用户密码
export const editUserPassword = (params: any) => {
  return request({
    url: `/PromotionAppuni/passwordPut`,
    method: 'put',
    data: params
  })
}

// 用户查询
export const getQueryUser = (data:Object) => get('/PromotionAppuni/queryUser',data)

// 查询app推广任务
export const getQueryTask = (data:Object) => post('/PromotionAppuni/queryTaskList',data)

// app推广任务: 删除
export const taskInfoDelete = (id: string) => {
  return request({
    url: `/PromotionAppuni/TaskInfoDelete/${id}`,
    method: 'delete'
  })
}
// app推广任务: 新增 | 编辑
export const taskInfoAddOrEdit = (params: inter_taskInfoAddOrEdit) => {
  return request({
    url: `/PromotionAppuni/TaskInfoSubmitReview`,
    method: 'post',
    data: params
  })
}
// app推广任务: 数据维护
export const taskDataMaintenance = (params: inter_taskDataMaintenance) => {
  return request({
    url: `/PromotionAppuni/TaskDataMaintenance`,
    method: 'post',
    data: params
  })
}
// app推广任务: 数据维护上传图片
export const uploadImage = (params: inter_uploadImage) => {
  let formData = new FormData()
  if (params.files.length > 0) {
    params.files.forEach((item: any) => {
      formData.append('files', item);
    })
  } 
  formData.append('textArray', params.textArray);
  formData.append('fileArray', params.fileArray);
  formData.append('taskInfoId', params.taskInfoId);
  return request({
    url: "/PromotionAppuni/uploadImage",
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data: formData
  })
}
// app推广任务：获取上一次数据维护信息
export const lastMaintenanceData = (id: string) => {
  return request({
    url: `/PromotionAppuni/queryTaskTutorial?taskInfoId=${id}`,
    method: 'post'
  })
}
// app推广任务：新增上传图片
export const taskAddUploadImg = (params: inter_taskAddUploadImg) => {
  let formData = new FormData()
  formData.append('file', params.file);
  formData.append('taskInfoId', params.taskInfoId);
  return request({
    url: "/PromotionAppuni/TaskInfoFile",
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data: formData
  })
}

// 用户管理: 新增用户
export const userAdd = (params: dialog_formType) => {
  return request({
    url: '/PromotionAppuni/userAdd',
    method: 'post',
    data: params
  })
}
// 用户管理: 删除用户
export const userDelete = (id: string) => {
  return request({
    url: `/PromotionAppuni/userDelete/${id}`,
    method: 'delete'
  })
}
// 用户管理: 修改用户
export const userPut = (params: dialog_formType) => {
  return request({
    url: '/PromotionAppuni/userPut',
    method: 'put',
    data: params
  });
}

// 凭证信息: 获取列表
export const getVoucherList = (params: inter_getVoucherList) => {
  return request({
    url: '/PromotionAppuni/queryVoucher',
    method: 'post',
    data: params
  })
}
// 凭证信息: 送审
export const voucherApproval = (params: inter_voucherApproval) => {
  return request({
    url: '/PromotionAppuni/auditingVoucher',
    method: 'get',
    params: params
  })
}
// 凭证信息: 删除
export const voucherDelte = (id: string) => {
  return request({
    url: `/PromotionAppuni/voucherDelete/${id}`,
    method: 'delete'
  })
}

// 凭证审核: 列表
export const cretifiList = (params: inter_cretifiList) => {
  return request({
    url: `/PromotionAppuni/voucherReview`,
    method: 'post',
    data: params
  })
}
// 凭证审核: 审核
export const voucherAudit = (params: inter_voucherAudit) => {
  return request({
    url: "/PromotionAppuni/informationReview",
    method: 'get',
    params: params
  })
}

// 商品管理: 获取列表
interface inter_shopList {
  type: string | number,
  title: string,
  popular: string | number,
  isNew: string | number,
  page: number
}
export const shopList = (params: inter_shopList) => {
  return request({
    url: "/SourceCode/Game",
    method: 'post',
    data: params
  })
}
// 商品管理: 删除商品
export const shopDel = (id: string) => {
  return request({
    url: "/SourceCode/gameDelete/" + id,
    method: 'delete'
  })
}
// 商品管理: 新增
export const shopAdd = (params: any) => {
  let formData = new FormData()
  if (params.files.length > 0) {
    params.files.forEach((item: any) => {
      formData.append('files', item);
    })
  } 
  formData.append('title', params.title);
  formData.append('editionLang', params.editionLang);
  formData.append('fraction', params.fraction);
  formData.append('video', params.video);
  formData.append('popular', params.popular);
  formData.append('isNew', params.isNew);
  formData.append('type', params.type);
  formData.append('details', params.details);
  return request({
    url: "/SourceCode/uploadGame",
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data: formData
  })
}

// (源码)用户管理: 列表
interface inter_sourceUserList {
  page: number,
  userName: string,
  mailbox: string
}
export const sourceUserList = (params: inter_sourceUserList) => {
  return request({
    url: "/SourceCode/userInfo",
    method: 'post',
    data: params
  })
}

// (源码)订单管理: 列表
interface inter_orderList {
  page: string,
  sourceCodeinfoId: string,
  pay: number | string,
  fraction: string,
  title: string,
  orderNumber: string,
}
export const orderList = (params: inter_orderList) => {
  return request({
    url: "/SourceCode/queryOrder",
    method: 'post',
    data: params
  })
}
// 订单管理: 删除订单
export const orderDel = (id: string) => {
  return request({
    url: "/SourceCode/orderDelete/" + id,
    method: 'delete'
  })
}

// (源码)卡密信息: 列表
interface inter_fruitList {
  page: number,
  tocami: string,// 卡密
  integral: string,// 积分
  isUse: number | string, // 是否使用  0=未使用 1=已使用
}
export const fruitList = (params: inter_fruitList) => {
  return request({
    url: "/SourceCode/queryCami",
    method: 'post',
    data: params
  })
}
// 卡密信息: 新增
interface inter_fruitAdd {
  userId: string,
  integral: number
}
export const fruitAdd = (params: inter_fruitAdd) => {
  return request({
    url: "/SourceCode/addToCami",
    method: 'post',
    data: params
  })
}
// 卡密信息: 删除
export const fruitDel = (id: string) => {
  return request({
    url: "/SourceCode/deleteCami/" + id,
    method: 'delete'
  })
}

// 收益统计
export const revenueStatistics = (id: string) => {
  return request({
    url: "/PromotionAppuni/revenueStatistics",
    method: 'post',
    data: {
      userId: id
    }
  })
}

// 提现信息: 列表
interface inter_queryIncome {
  page: number,
  userName: string,
  zfbPay: string,
  // 0=送审中 1=审核通过 2=审核失败
  state: number | string,
  money: number | string
}
export const queryIncome = (params: inter_queryIncome) => {
  return request({
    url: "/PromotionAppuni/queryIncome",
    method: 'post',
    data: params
  })
}

// 提现审核: 列表
interface inter_queryIncome {
  auditor_id: string,
  auditor_name: string,
  id: string,
  // 0=送审中 1=审核通过 2=审核失败
  state: number | string,
  money: number | string
}
export const withdrawalReview = (params: inter_queryIncome) => {
  return request({
    url: "/PromotionAppuni/withdrawalReview",
    method: 'post',
    data: params
  })
}