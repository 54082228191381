import { cloneDeep } from 'lodash';

interface addNavList{
  name: string,
  path: string,
  active: boolean
}

const state = {
  nav_list: []
}

const getters = {}

const mutations = {
  INIT_NAVLIST(state: any, value: any) {
    const sess_navList = JSON.parse(sessionStorage.getItem('nav_list') as string)
    if (sess_navList == null) {
      if (state.nav_list.length == 0) {
        state.nav_list.push({
          name: '首页',
          active: true,
          path: '/home'
        })
      }
    } else {
      state.nav_list = sess_navList
    }
  },
  ADD_NAVLIST(state: any, value: addNavList) {
    state.nav_list.map((item: any) => item.active = false)
    state.nav_list.push({
      name: value.name,
      path: value.path,
      active: true
    })
    sessionStorage.setItem('nav_list', JSON.stringify(state.nav_list))
  },
  REMOVE_NAVLIST(state: any, value: number) {
    state.nav_list.splice(value, 1)
    sessionStorage.setItem('nav_list', JSON.stringify(state.nav_list))
  },
  UPDATE_NAVLIST(state: any, value: number) {
    state.nav_list.map((item: any) => item.active = false)
    state.nav_list[value]['active'] = true
    sessionStorage.setItem('nav_list', JSON.stringify(state.nav_list))
  },
  CLEAR_NAVLIST(state: any, value: any) {
    state.nav_list = cloneDeep(state.nav_list.filter((it: any) => {
      return it.name == '首页'
    }))
    state.nav_list[0].active = true
    sessionStorage.setItem('nav_list', JSON.stringify(state.nav_list))
  },
  CLEAR_OTHER(state: any, value: any) {
    let arr = cloneDeep(state.nav_list.filter((it: any) => {
      return it.name == '首页' || it.name == value
    }))
    if (arr[0].name == value) {
      arr[0].active = true
    } else {
      arr[arr.length - 1].active = true
    }
    state.nav_list = cloneDeep(arr)
    sessionStorage.setItem('nav_list', JSON.stringify(state.nav_list))
  },
  NAV_LOGOUT(state: any) {
    state.nav_list = []
    sessionStorage.removeItem('userinfo')
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}