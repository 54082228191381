export default {
  install(app: any) {
    // 权限指令(普通用户 和 管理员)
    // isRoot: 0 普通用户 1: 管理员
    app.directive("root", {
      mounted(el: any, binding: any) {
        const user = JSON.parse(sessionStorage.getItem('userinfo') as string)
        if (user.data.isRoot != 1) {
          el.style['display'] = 'none'
        }
      }
    })
  }
}